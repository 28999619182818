import FluxEventEmitter from '../FluxEventEmitter';
import assign           from 'object-assign';
import {doVxqlRequest}  from './VxqlStore';
import Flux             from '../Flux';
import Constants        from '../Constants';

const MEDIA_TYPES = {
    VIDEO: 'VIDEO',
    PHOTO: 'PHOTO',
};

const SOURCE_TYPES = {
    PURCHASED: 'PURCHASED',
    PINNED:    'PINNED',
};

const CHANGE_EVENTS = {
    tileExpandChange: 'tileExpandChange',
};

function getKey(args) {
    return JSON.stringify(args);
}

const _defaultFilter = {
    boughtVideos:    {filter: {mediaType: MEDIA_TYPES.VIDEO, sourceType: SOURCE_TYPES.PURCHASED}},
    pinnedVideos:    {filter: {mediaType: MEDIA_TYPES.VIDEO, sourceType: SOURCE_TYPES.PINNED}},
    messengerVideos: {filter: {mediaTypes: Constants.MessengerContent.VIDEO, searchString: ""}},
    feedVideos:      {typeGroups: [Constants.Feed.Filter.CLIP_POSTS], bought: true, mode: Constants.Feed.Mode.MIXED},

    boughtPhotos: {filter: {mediaType: MEDIA_TYPES.PHOTO, sourceType: SOURCE_TYPES.PURCHASED}},
    pinnedPhotos: {filter: {mediaType: MEDIA_TYPES.PHOTO, sourceType: SOURCE_TYPES.PINNED}},

    messengerPhotos: {filter: {mediaTypes: Constants.MessengerContent.BITMAP, searchString: ""}},
    feedPhotos:      {typeGroups: [Constants.Feed.Filter.PHOTOS_POSTS], bought: true, mode: Constants.Feed.Mode.MIXED},

    audio: {filter: {mediaTypes: Constants.MessengerContent.AUDIO, searchString: ""}}
};

const _mediathekData = {};
const _messengerData = {};
const _feedData      = {};

const MediathekStore = assign({}, FluxEventEmitter.prototype, {
    onTileExpand: function(videoId) {
        MediathekStore.emit(CHANGE_EVENTS.tileExpandChange, videoId);
    },

    addTileExpandListener: function(callback) {
        this.on(CHANGE_EVENTS.tileExpandChange, callback);
    },

    removeTileExpandListener: function(callback) {
        this.removeListener(CHANGE_EVENTS.tileExpandChange, callback);
    },

    getBoughtVideos(first, offset, order, search, callback) {
        const filter = _defaultFilter.boughtVideos;
        getMediaLibraryData({...filter, first, offset, order, search}, callback);
    },

    getPinnedVideos(first, offset, order, search, callback) {
        const filter = _defaultFilter.pinnedVideos;
        getMediaLibraryData({...filter, first, offset, order, search}, callback);
    },

    getMessengerVideos(first, offset, order, search, callback) {
        const filter        = _defaultFilter.messengerVideos;
        filter.searchString = search;
        getMessengerData({...filter, first, offset, order}, callback);
    },

    getFeedVideos(first, after, callback) {
        const filter = _defaultFilter.feedVideos;
        filter.first = first;
        filter.after = after;
        getFeedData(filter, callback);
    },

    getBoughtPhotos(first, offset, order, search, callback) {
        const filter = _defaultFilter.boughtPhotos;
        getMediaLibraryData({...filter, first, offset, order, search}, callback);
    },

    getPinnedPhotos(first, offset, order, search, callback) {
        const filter = _defaultFilter.pinnedPhotos;
        getMediaLibraryData({...filter, first, offset, order, search}, callback);
    },

    getMessengerPhotos(first, offset, order, search, callback) {
        const filter        = _defaultFilter.messengerPhotos;
        filter.searchString = search;
        getMessengerData({...filter, first, offset, order}, callback);
    },

    getFeedPhotos(first, after, callback) {
        const filter = _defaultFilter.feedPhotos;
        filter.first = first;
        filter.after = after;
        getFeedData(filter, callback);
    },

    getAudio(first, offset, order, search, callback) {
        const filter        = _defaultFilter.audio;
        filter.searchString = search;
        getMessengerData({...filter, first, offset, order}, callback);
    },
});

function getMediaLibraryData(args, callback) {
    const key = getKey(args);
    if (_mediathekData[key]) {
        callback(_mediathekData[key]);
    } else {
        loadMediaLibraryData(args, callback);
    }
}

function getMessengerData(args, callback) {
    const key = getKey(args);
    if (_messengerData[key]) {
        callback(_messengerData[key]);
    } else {
        loadMessengerData(args, callback);
    }
}

function getFeedData(args, callback) {
    const key = getKey(args);
    if (_feedData[key]) {
        callback(_feedData[key]);
    } else {
        loadFeedData(args, callback);
    }
}

const MediaLibraryVideoAsset = `
    ...on MediaLibraryVideoAsset {
        asset {
            id
	        title
	        duration
	        released
            description(full: true)
	        preview {
	            id
	            aspectRatio
	            images(size: [w320, w640, w1280], placeholder: true) {
	                size
	                url
	            }
	        }
	        previewThumbnails(first: 100) {
	            id
	            aspectRatio
	            images(size: [w320, w640, w1280], placeholder: true) {
	                size
	                url
	            }
	        }
	        model {
	            id
	            name
	            linkVX(internal: true)
                online
                avatar {
                    url(size: w60)
                }
	        }
	        linkVX(internal: true)
			url(profile: hls)
			previewVideo {
			    url
			}
			guestInfo {
			    bought
			    gifted
			}
			price {
			    value
			    currency
			}
			basePrice {
			    value
			    currency
			}
        }
    }
`;

const MediaLibraryGalleryAsset = `
    ...on MediaLibraryGalleryAsset {
        asset {
			id
	        title
	        description
	        ageRating
	        free
	        guestInfo {
	            bought
	            gifted
	            liked
	            flatrate
	        }
	        price {
	            value
	            currency
	        }
	        basePrice {
	            value
	            currency
	        }
	        preview {
	            id
	            aspectRatio
	            images(size: [w320, w640, w1280], placeholder: true) {
	                size
	                url
	            }
	        }
	        previews {
	            id
	            width
	            height
	            aspectRatio
	            orientation
	            images(size: [w320, w640, w1280], placeholder: true) {
	                size
	                url
	            }
	        }
	        rating {
	            likes
	        }
	        model {
	            id
	            name
	            linkVX(internal: true)
                online
                avatar {
                    url(size: w60)
                }
	        }
	        linkVX(internal: true)
			isVipContent
        }
    }
`;

function getAssetByType(type) {
    switch (type) {
        case MEDIA_TYPES.PHOTO:
            return MediaLibraryGalleryAsset;
        case MEDIA_TYPES.VIDEO:
        default:
            return MediaLibraryVideoAsset;
    }
}

function loadMediaLibraryData(args, callback) {
    const asset = getAssetByType(args.filter.mediaType);

    const query = `
	query(
        $filter: MediaLibraryFilter,
        $first: Int = 20,
        $offset: Int = 0,
        $search: String = "",
        $order: ModelVideosOrder = ${Flux.Constants.Vxql.MyVisitXMediathekSort.NEWEST}
    ) {
		guest {
		  mediaLibrary(filter: $filter, first: $first, offset: $offset, order: $order, search: $search) {
            total
            items {
                ${asset}
            }
          }
		}
	  }
	`;

    doVxqlRequest(query, args).then((result) => {
        const data          = result.data.guest.mediaLibrary;
        const key           = getKey(args);
        _mediathekData[key] = data;
        callback(data);
    });
}

function loadMessengerData(args, callback) {
    Flux.MessengerContent.getMessengerContent(args.first, args.offset, args.filter, args.order, (result) => {
        const data          = result.data.guest.messengerContent;
        const key           = getKey(args);
        _messengerData[key] = data;
        callback(data);
    });
}

function loadFeedData(args, callback) {
    Flux.Feed.getFeedData(args, (res) => {
        const data     = res.data.feed.posts;
        const key      = getKey(args);
        _feedData[key] = data;
        callback(data);
    });
}

export default MediathekStore;
